.shipIdsPanel {
	max-width: 100%;
	margin: 0.3rem 0;
	padding: 0.3rem;
	font-style: italic;
	color: var(--blue-1);
	display: flex;
	flex-wrap: wrap;
	flex: auto;
	border: 1px solid var(--blue-4);
	height: auto;
	&:hover{
		border: 1px solid var(--blue-1);
	}

	legend{
		padding: 0.2rem;
	}

	input {
		height: 1.5rem;
		padding: 0.5rem;
		min-width: 60%;
    max-width: 60%;
		margin: 0.5rem;
		border: 1px solid var(--blue-5);
		outline: none;
		background-color: var(--blue-1);
		&:hover {
			border: 1px solid var(--chile-brown);
		}
		&:focus {
			background-color: var(--chile-brown);
			border-bottom: 2px solid white;
		}
	}
	button {
		margin: 0.5rem;
		width: 2.5rem;
		background-color: var(--blue-4);
		outline: none;
		color: white;
		font-weight: 700;
		border: 1px solid var(--blue-5);
		&:hover {
			border: 1px solid var(--blue-1);
			background-color: var(--blue-5);
		}
	}
	
	.list {
		margin: 0.5rem;
		max-width: 100%;
		height: 100%;
		flex: auto;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding: 0.2rem;
		border: 1px solid var(--blue-4);
		gap: 0.3rem;
		cursor: crosshair;
		.listItem{
    	overflow: auto;
			border: 1px solid var(--blue-4);
			padding: 0.2rem;
			display: flex;
			justify-content: space-between;
			&:hover{
				border: 1px solid var(--blue-1);
			}
			.id {
				color: var(--chile-brown);
				font-weight: bold;
			}
			.name {
				color: var(--blue-1);
				font-style: italic;
			}
		}
	}
}