.name {
	display: flex;
	width: 100%;
	margin: 0.3rem 0;
	padding: 0.5rem;
	border: 1px solid var(--blue-4);
	height: 14vh;
	justify-content: center;
	align-items: center;

	&:hover{
		border: 1px solid var(--blue-1);
	}

	legend {
		font-style: italic;
		color: var(--blue-1);
	}

	input {
		height: 1.5rem;
		padding: 0.5rem;
		width: 100%;
		border: none;
		outline: none;
		background-color: var(--blue-1);
		&:hover {
			border: 1px solid var(--chile-brown);
		}
		&:focus {
			background-color: var(--chile-brown);
			border-bottom: 2px solid white;
		}
	}
}