.zonesList {
	padding: 0.5rem;
	border: 1px solid var(--blue-4);
	overflow-y: auto;
	max-height: 50rem;
	margin-top: auto;
	background: var(--blue-5);
	&:hover{
		border: 1px solid var(--blue-1);
	}

	.zoneItem {
		border: 1px solid var(--blue-4);
		border-radius: 5px;
		margin: 0.5rem 0;
		padding: 0.4rem;
		overflow: hidden;
		
		.heading{
			display:flex;
			justify-content: space-between;
			cursor: pointer;
			&:hover{
				color: var(--chile-brown);
			}
			.name {
				font-weight: bold;
				width: 100%;
			}
			.editButton, .deleteButton{
				min-width: 1.4vw;
				height: 1.4vw;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.editButton{
				background-color: black;
				border: 1px solid var(--blue-2);
				color: var(--blue-4);
				margin-right: 0.2vw;
				&:hover{
					background-color: var(--blue-2);
					border: 1px solid black;
					color: black;
				}
			}
			.deleteButton{
				background-color: black;
				border: 1px solid rgb(255, 132, 132);
				color: red;
				&:hover{
					background-color: red;
					border: 1px solid black;
					color: black;
				}
			}


		}
		.toolbar{
			margin-top:0.5rem;
			input {
				cursor: pointer;
				height: 0.8rem;
				width: 0.8rem;
			}
			&:hover {
				color: var(--chile-brown);
			}
		}
		&:hover{
			background-color: black;
		}
	}
}