.LoginScreen{
	height: 100vh;
	background-image: url('p1.jpg');
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 1.5rem;
	font-style: italic;

	.loginBox{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		height: 50vh;
		width: 25vw;
		border: 1px solid var(--blue-3);
		border-radius: 10px;
		box-shadow: 15px 15px 10px 1px black;

		label{
			width: 90%;
		}

		input{
			width: 100%;
			height: 3rem;
			border-radius: 0.5vw;
			border: none;
			outline: none;
			padding: 1rem;
			background: var(--blue-5);
			color: white;
			font-size: 1.2rem;

			&:hover{
				border: 1px solid var(--chile-brown);
			}
			&:focus{
				background: var(--blue-4);
			}
		}

		.button{
			background: var(--blue-3);
			border: 2px solid var(--blue-3);
			padding: 1vh 2vw;
			border-radius: 0.5vw;
			cursor: pointer;
			transition: all 0.5s;
			&:hover{
				color: white;
				background: none;
				border: 2px solid var(--blue-3);
			}
		}
	}
}