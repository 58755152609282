.configurableGrid {
	display: flex;
	flex-wrap: wrap;
	min-width: 38%;
	max-width: 38%;
	margin: 0 0.5rem;
	flex-direction: column;

	.topPanel, .bottomPanel {
		display: flex;
		width: 100%;
		height: 45%;
		background-color: var(--blue-5);
		justify-content: center;
		align-items: center;
		overflow: auto;
	}
}