.MapScreen {
	height: 100%;
	display: flex;

	.markers_bar{
		display: flex;
		flex-direction:column;
		max-width: 22vw;
		min-width: 22vw;
		background-color: var(--blue-5);
		color: var(--gray);
		padding: 0 0.5rem;
		overflow-y: scroll;

		.tabs{
			background-color: var(--blue-1);
			border: 1px solid var(--blue-4);
			display: flex;

			button {
				border: none;
				outline: none;
				background-color: var(--blue-5);
				height: 100%;
				width: 50%;
				padding: 0.2vw;
				color: var(--blue-3);
				text-transform: uppercase;
				font-size: 1vw;
				cursor: pointer;
			}
			.btn_on {
				background-color: var(--chile-brown);
				color: var(--blue-5);
				box-shadow: inset 2px 2px 1px 1px black;
			}
		}

		.locations, .zones {
			flex: auto;
			border: 1px solid var(--blue-4);
			display: flex;
			flex-direction: column;
		}

		.btn_add {
			margin: 1rem;
			background-color: var(--yellow);
			border: none;
			border-radius: 5px;
			cursor: pointer;
			align-self: center;
			&:hover {
				background-color: var(--blue-1);
				color: white;
			}
		}

		.btn_drawing {
			background-color: var(--blue-1);
   	 	color: white;
    	box-shadow: 1px 1px 10px 2px white;
		}

		.list {
			background-color: var(--blue-5);
			padding: 0 1rem;
			margin-top: 1rem;
 			overflow-y: auto;
			height: 0rem;
			flex: auto;
			display: flex;
			flex-direction: column;

			.location_item {
				cursor: pointer;
				&:hover {
					background-color: var(--blue-4);
				}
			}
		}

		.locations {
			.types{
				height: 2rem;
				align-items: center;
				background-color: var(--blue-4);
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 0.3rem;
				.title {
					width: 50%;
				}
				.buttons {
					width: 50%;
					display: flex;	
					.btn_small {
						width: 30%;
						padding: 0.2rem;
						border: 1px solid white;
						justify-content: center;
						display: flex;
						margin-right: 0.1rem;
						background-color: var(--blue-4);
						color: white;
					}
					.btn_on {
						background-color: var(--yellow);
						color: var(--blue-5);
					}			
				}
			}

			.info {
				display: flex;
				flex-direction: column;
				padding: 1rem;
				padding-bottom: 0;

				label {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					margin-bottom: 1rem;
					span {
						margin-right: 0.5rem;
						min-width: 11ch;
					}
				}

				input, textarea {
					width: 9rem;
					padding: 0.2rem;
					border: none;
					outline: none;
					background-color: var(--blue-1);
					&:hover {
						border: 1px solid var(--yellow);
					}
					&:focus {
						background-color: var(--yellow);
						border-bottom: 2px solid white;
					}
				}
				input {
					height: 1.5rem;
				}
				textarea {
					height: 5rem;
					resize: none;
				}
			}
		}
	}
}