.zoneMode {
	display: flex;
	flex-direction: column;
	width: 48%;
	margin: 0.3rem 0;
	padding: 0.5rem;
	border: 1px solid var(--blue-4);
	&:hover{
		border: 1px solid var(--blue-1);
	}

	legend {
		font-style: italic;
		color: var(--blue-1);
	}

	select {
		background-color: var(--blue-4);
		color: white;
	}
}