.miniBooleanSet {
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	width: 100%;
	border: 1px solid var(--blue-4);
	height: auto;
	&:hover{
		border: 1px solid var(--blue-1);
	}
	
	legend {
		color: var(--blue-1);
		padding: 0.2rem;
		font-style: italic;
	}

	label {
		color: var(--gray);
		margin-top: 0.2rem;
		display: flex;
		align-items: center;
		&:hover {
			color: var(--chile-brown);
		}
	}
}

input[type=checkbox] {
	vertical-align: middle;
	width: 1rem;
	height: 1rem;
	background-color: var(--blue-4);
	cursor: pointer;
	border-radius: 50%;
	margin-right: 0.3rem;
	appearance: none;
	transition: all 0.5s;
	-webkit-appearance: none;
	-moz-appearance: none;
	margin-top: 0.1rem;
}
input[type=checkbox]:checked {
	background-color: var(--chile-brown);
	box-shadow: 1px 1px 10px 1px var(--yellow);
}