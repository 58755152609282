.alarmDetail {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: var(--blue-5);

	.left_panel {
		display: flex;
		flex-direction: column;
		width: 65%;
		background-color: var(--blue-5);
		border: 1px solid var(--blue-1);
		border-right: none;
		padding: 1rem;
		z-index: 2;
		.icon {
			height: 35%;
			width: auto;
			svg {
				width: 90%;
				height: 90%;
				fill: rgb(0, 0, 40);
			}
			position: absolute;
    	z-index: -1;
		}
		.title {
			font-size: var(--font-wide-4);
			display: flex;
			text-transform: capitalize;
			z-index: 1;
			color: var(--chile-brown);
			font-weight: bold;
		}
		.info {
			font-size: var(--font-wide-2);
			display: flex;
			flex-direction: column;
			color: var(--gray);
			font-style: italic;
			z-index: 1;
		}
		.specificInfo{
			margin-top: 1vh;
		}
		
		.buttons {
			font-size: var(--font-wide-2);
			display: flex;
			margin-top: auto;
	    z-index: 1;
			.button {
				margin-right: 1rem;
				padding: 0.3rem 1rem;
				border-radius: 5px;
				cursor: pointer;
			}
			.close {
				background: var(--chile-brown);
				color: black;
				border: 1px solid black;
				&:hover {
					background-color: var(--blue-5);
					border: 1px solid var(--chile-brown);
					color: var(--chile-brown);
				}
			}
			.deactivate {
				background-color: var(--chile-brown);
				color: white;
				border: 1px solid var(--blue-5);
				&:hover {
					background-color: var(--blue-5);
					border: 1px solid var(--chile-brown);
					color: var(--chile-brown);
				}
			}
		}
	}
	
	.right_panel {
		display: flex;
		flex-direction: column;
		max-width: 35%;
		min-width: 35%;
		background-color: var(--blue-5);
		border: 1px solid var(--blue-1);
		border-left: none;
		padding: 1rem;
	}
}