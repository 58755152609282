.alarmCard {
	display: flex;
	padding: 0.7vh;
	margin: 0.2em;
	border: 1px solid var(--blue-2);
	background-color: var(--blue-5);
	width: 95%;
	min-height: 8vh;
	max-height: 8vh;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	font-size: var(--font-wide-2);
	

	.notification{
		color: red;
		display: flex;
    height: 100%;
	}

	.tag{
		margin-top: auto;
	}
	.Válida{
		color: rgb(0, 255, 0);
		border: none;
	}
	.Crítica{
		color: yellow;
		border: none;
	}
	.Inválida{
		color: red;
		border: none;
	}

	.body {
		display: flex;
	}

	&:hover {
		box-shadow: 0px 0px 5px 1px;
		border: 1px solid var(--blue-1);
	}

	.icon {
		height: 100%;
    background-color: var(--chile-blue);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 30%;

		svg {
			height: 90%;
			fill: white;
		}
	}

	.name {
		color: white;
		margin-left: 0.3rem;
		margin-right: auto;
	}
}

.cards {
	overflow-y: auto;
	width: 100%;
	height: 100%;
	flex: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.active {
	transition: all 0.5s;
	background-color: var(--blue-1);
	box-shadow: 0px 0px 5px 0px var(--blue-1);
}

.hasNotification{
	border: 1px solid rgba(255, 0, 0, 0.6);
}

.Válida{
	border: 1px solid rgb(0, 255, 0);
}
.Crítica{
	border: 1px solid yellow;
}
.Inválida{
	border: 1px solid red;
}