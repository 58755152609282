.alarmsMenu{
	li{
		list-style: none;
		padding: 0.5vw;
		cursor: pointer;
		color: var(--blue-2);
		border-bottom: 1px solid black;
		font-style: italic;

		&:hover{
			background: var(--blue-4);
			color: var(--chile-brown);
			border-radius: 5px;
		}
	}
}