.container {
  height: 100%;

  .searchbar {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--yellow);

    input,
    select {
      margin: 1rem;
    }
  }

  .reports_container {
    display: flex;
    height: 100%;

    .icon svg {
      height: 100%;
      width: 2rem;
      margin: 0.6em;
    }

    .alarms_list {
      border: 3px solid var(--blue-2);
      background-color: var(--blue-3);
      overflow-y: auto;
      flex: 3;
      padding: 0.5rem;
      height: 100%;
      h2 {
        text-align: center;
        color: white;
        display: none;
      }

      table {
        width: 100%;
        color: var(--gray);
        margin-top: 1em;

        thead {
          color: var(--chile-brown);
          background-color: var(--blue-5);
          font-weight: bold;
          font-style: italic;
          font-size: 1rem;
          text-transform: uppercase;
          tr {
            height: 4rem;
            cursor: pointer;
          }
        }

        tbody {
          font-size: 0.8rem;
          tr {
            &:hover {
              color: var(--yellow);
              background-color: var(--blue-4);
            }
          }
        }

        tr {
          &:nth-child(even) {
            background-color: var(--blue-2);
          }
          td {
            padding: 0.2rem;
          }
        }
      }
    }

    .alarm_form {
      flex: 2;
      height: 100%;
      padding: 1rem;

      .form_buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 2.5rem;

        button {
          width: 10rem;
          height: 3rem;
          border: 1px solid var(--blue-1);
          color: var(--gray);
          outline: none;
          background-color: var(--blue-5);

          &:hover {
            border: 1px solid var(--yellow);
            color: var(--yellow);
            background-color: var(--blue-4);
          }
        }
      }

      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        label {
          display: flex;
          flex-direction: column;
          width: 40%;
          margin: 0.5%;
          color: var(--gray);
          font-size: 1rem;

          input {
            height: 2rem;
            width: 13rem;
            outline: none;
            border: none;
            background-color: var(--blue-3);
            padding: 0.5rem;

            &:focus {
              border-bottom: 3px solid var(--gray);
              background-color: var(--blue-1);
            }
          }
        }
      }
    }
  }
}
