.goTo{
	display: flex;
	align-items: baseline;

	button{
		font-size: var(--font-wide-2);
		border-radius: 0.5rem;
		padding: 0.2rem 0.6rem;
		border: 1px solid var(--blue-1);
		background: var(--blue-1);
		color: white;
		outline: none;
		margin: var(--font-wide-1);
		cursor: pointer;
		&:hover{
			background: var(--blue-5);
			border: 1px solid var(--blue-1);
		}
	}

	.text {
		font-size: var(--font-wide-2);
		margin-right: 0.2vw;
		color: var(--blue-3);
		&:hover, &:focus {
			color: var(--blue-1);
		}
	}

	.input{
		font-size: var(--font-wide-2);
		max-width: 4vw;
		margin-right: 2vw;
		border: none;
		outline: none;
		background: var(--blue-4);
		color: var(--blue-1);
		padding: 0.2rem;
		border-radius: 5px;
		transition: all 0.2s;
		&:hover {
			background: var(--blue-1);
			color: white;
		}
		&:focus {
			background: var(--blue-1);
			color: white;
			max-width: 7vw;
		}
	}
}