.zoneInfo{
	display: flex;
	flex-direction: column;
	border: 1px solid rgba(255, 255, 255, 0.199);
	border-radius: 5px;
	margin: 1rem;
	padding: 1rem;
	gap: 1rem;

	ul{
		font-size: 1vw;
		margin-left: 2rem;
    font-style: italic;
		color: var(--blue-1);
	}
}