.SizesMenu{

	.menuTitle{
		background: black;
		padding: 0.2vw;	
		font-weight: bold;
	}

	li{
		list-style: none;
		padding: 0.5vw;
		cursor: pointer;
		color: var(--blue-2);
		border-bottom: 1px solid black;
		font-style: normal;
		margin-left: 1rem;
		&:hover{
			background: var(--blue-4);
			color: var(--chile-brown);
			border-radius: 5px;
		}
	}
}