.zonesbar {
	display: flex;
	flex-direction: column;
	flex: auto;

	font-size: var(--font-wide-2);

	.selectionsPanel{
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.mainButtons{
		display: flex;
		justify-content:space-around;
		align-items: center;
	}
}

.newMarkerPanel {
	display: flex;
	overflow-y: auto;
	padding: 0.5rem;
	flex-wrap: wrap;
}
.invisible {
	display: none;
}