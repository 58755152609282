	.colorsPanel{
		width: 100%;
		margin: 0.3rem 0;
		display: flex;
		justify-content: space-between;
		border: 1px solid var(--blue-4);
		padding: 0.5rem;
		height: 15vh;
    flex-direction: row;
		&:hover{
			border: 1px solid var(--blue-1);
		}

		legend {
			font-style: italic;
			color: var(--blue-1);
		}
		div {
			cursor: pointer;
			width: var(--font-wide-4);
			height: var(--font-wide-4);
			display: flex;
			justify-content: center;
			vertical-align: middle;
			padding: 0;
			margin: 0.2rem;
			border-radius: 2px;
			color: var(--blue-3);
			transition:all 0.7s;
		}

		.active {
			box-shadow: 0px 0px 10px 1px;
		}
		
		.red{
			border: 3px solid red;
			&:hover {
				background-color: rgb(255, 131, 131);
			}
			&.active {
				box-shadow: 0px 0px 10px 1px red;
				background-color: red;
			}
		}

		.green {
			border: 3px solid green;
			&:hover {
				background-color: rgb(128, 255, 128);
			}
			&.active {
				box-shadow: 0px 0px 10px 1px green;
				background-color: green;
			}
		}

		.blue {
			border: 3px solid blue;
			&:hover {
				background-color: rgb(93, 93, 255);
			}
			&.active {
				box-shadow: 0px 0px 10px 1px blue;
				background-color: blue;
			}
		}

		.yellow {
			border: 3px solid yellow;
			&:hover {
				background-color: rgb(255, 255, 164);
			}
			&.active {
				box-shadow: 0px 0px 10px 1px yellow;
				background-color: yellow;
			}
		}

		.white {
			border: 3px solid white;
			&:hover {
				background-color: rgb(209, 209, 209);
			}
			&.active {
				box-shadow: 0px 0px 10px 1px white;
				background-color: white;
			}
		}

		.black {
			border: 3px solid black;
			&:hover {
				background-color: rgb(24, 24, 24);
			}
			&.active {
				box-shadow: 0px 0px 10px 1px black;
				background-color: black;
				border: 3px solid white;
			}
		}
	}