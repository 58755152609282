.mapSection {
	display: flex;
	flex-direction: column;
	height:100%;
	width: 100%;
	
	.toolbar {
		display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7%;
    border: 1px solid var(--blue-4);
    margin-bottom: 0.5rem;
		
		&:hover {
			border: 1px solid var(--blue-1);
		}
	}
	
	.map {
		height: 95%;
	}
}