.SliderSet {
	.toolbar{
		display: flex;
		justify-content: flex-end;
	}
	
	fieldset {
		display: flex;
		flex-direction: column;
		padding: 1rem;
		margin: 1rem;
		width:fit-content;
		border: 1px solid var(--blue-4);
    height: 95%;
    overflow-y: auto;
		&:hover{
			border: 1px solid var(--blue-1);
		}
		
		legend {
			background-color: var(--blue-4);
			color: var(--gray);
			padding: 0.5rem;
		}

		label {
			color: var(--gray);
			margin-bottom: 0.6rem;
			display: flex;
			&:hover{
				color: var(--blue-1);
			}

			.text {
				margin-right: 1rem;
			}

			input {
				margin: 0 1rem;
			}

			.slider_container {
				display: flex;
				justify-content: flex-end;
				margin-left: auto;
			}
		}

	}

	input[type=checkbox] {
		vertical-align: middle;
		min-width: 1.5rem;
		min-height: 1.5rem;
		background-color: var(--blue-5);
		cursor: pointer;
		border-radius: 50%;
		margin-right: 0.5rem;
		appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
	}
	input[type=checkbox]:checked {
		background-color: var(--yellow);
		box-shadow: 1px 1px 10px 1px var(--yellow);
	}
}