.HideOptions {

	fieldset {
		display: flex;
		flex-direction: column;
		padding: 1rem;
		margin: 1rem;
		width:fit-content;
		border: 1px solid var(--blue-4);
		height: auto;
		&:hover{
			border: 1px solid var(--blue-1);
		}
		
		legend {
			background-color: var(--blue-4);
			color: var(--gray);
			padding: 0.5rem;
		}

		label {
			color: var(--gray);
			margin-bottom: 0.6rem;
			display: flex;
			cursor: pointer;
			&:hover{
				color: var(--blue-1);
			}

			:first-child {
				margin-right: 2rem;
			}

			input {
				width: 3.5rem;
				height: 1.5rem;
				margin-left: auto;
				background-color: var(--yellow);
				outline: none;
				border: none;
				padding: 0.5rem;

				&:focus {
					border-bottom: 3px solid var(--yellow);
					background-color: var(--blue-5);
					color: var(--gray);
				}
			}
		}
	}
}
