.alarmsForVessel {
	overflow-y: auto;
	width: 100%;
	height: 100%;
	flex: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: var(--blue-5);
}

.alarmCard {
	display: flex;
	padding: 0 0.2rem;
	margin: 0.2rem;
	border: 1px solid var(--chile-brown);
	background-color: var(--blue-5);
	width: 94%;
	min-height: 4.8vh;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	cursor: pointer;

	.body {
		display: flex;
	}

	&:hover {
		box-shadow: 0px 0px 5px 1px;
		border: 1px solid var(--blue-1);
	}

	.icon {
		width: var(--font-wide-2);
    height: var(--font-wide-2);
		color: white;
    display: flex;
    align-items: center;
    justify-content: center;
		margin-right: 0.5rem;
		font-size: var(--font-wide-2);
		z-index: 0;

		svg {
			width: 90%;
			height: 90%;
			fill: var(--chile-brown);
		}
	}

	.name {
		font-size: var(--font-wide-1);
		color: white;
		z-index: 1;
	}
}


