.leaflet-popup-content-wrapper {
	/* background-color: rgba(19, 111, 159,0.5); */
	background-color: rgba(0, 54, 167, 0.5);
	border: 1px solid var(--blue-5);
	box-shadow: 3px 3px 15px 2px black;
	display: flex;
	color: var(--blue-1);
}

.details {
	display: flex;
	flex-direction: column;
	line-height: 0.5rem;
	justify-content: flex-end;
	align-items: flex-start;
	margin-top: 1.5rem;
}

.leaflet-popup-content p{
	margin: 0.3rem 0;
}

.tooltip {
	background: var(--blue-5);
	color: white;
	padding: 0.1em;
	font-size: 0.7rem;
}
.leaflet-tooltip {
	background: var(--blue-5);
	border: none;
}
.leaflet-tooltip::before {
	display: none;
}