.validity_options {
	height: 3rem;
	margin-top: auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: var(--blue-5);
	border-radius: 5rem;
	border: 1px solid var(--blue-4);
	&:hover{
		border: 1px solid var(--blue-1);
	}

	.valid{
		color: rgb(0, 255, 0);
	}
	.critic{
		color: var(--chile-brown);
	}
	.invalid{
		color: red;
	}

	label {
		cursor: pointer;
		filter: saturate(15%);
		&:hover{
			filter: saturate(100%);
		}
		
	}

	.active{
		font-weight: bold;
		text-shadow: 0px 0px 6px;
		filter: saturate(100%);

	}

	svg {
		vertical-align: bottom;
		font-size: 1.2rem;
	}
}