.save, .cancel {
	font-size: var(--font-wide-2);
	margin: 0.7rem 0;
	padding: 0.5rem 1.4rem;
	border-radius: 5px;
	align-self: center;
	outline: none;
	transition: 0.2s;
	cursor: pointer;
}

.save {
	background-color: var(--blue-1);
	color: white;
	border: 1px solid var(--blue-1);
	&:hover {
		background-color: var(--blue-5);
		color: var(--blue-1);
	}
}

.cancel {
	background-color: var(--blue-5);
	color: var(--chile-brown);
	border: 1px solid var(--chile-brown);
	&:hover {
		background-color: var(--chile-brown);
		color: var(--blue-5);
	}
}