.newContextMenu {
	position: absolute;
	background: var(--blue-4);
	color: var(--blue-1);
	padding: 1vh;
	margin: 1vh;
	z-index: 999;
	max-height: 80%;
	overflow-y: auto;
	display: flex;

	.menuItems{
		background: var(--blue-5);
		padding: 0.5vw;
		border: 1px solid var(--blue-5);
		&:hover{
			border: 1px solid var(--blue-1);
		}
	}
}