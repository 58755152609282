.ShipsLegend{
	width: 25vw;
	color: white;
	display: flex;
	flex-direction: column;

	.row{
		display: flex;
		align-items: center;
		border: 1px solid var(--blue-5);
		cursor: pointer;
		&:hover{
			border: 1px solid var(--blue-1);
		}
		
		.png img{
			height: auto;
			width: 1rem;
			margin-right: 1rem;
		}
	}
}