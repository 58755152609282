.container {
	display: flex;
	flex-direction: column;
	max-width: 100vw;
	height: 100vh;
	background-color: var(--blue-5);
}

.desktop {
	background-color: var(--blue-5);
	height: 86vh;
}