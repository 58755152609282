.shipInfo {
	font-size: 2vh;
	padding: 0.3rem;
	overflow-y: auto;
	height: 100%;
	background-color: var(--blue-5);
	color: var(--gray);
	font-style: italic;
	border: 1px solid var(--blue-1);
	border-top: none;
  min-width: 80%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.imageContainer {
		height: 100%;
    min-width: 40%;
    border: 1px solid var(--blue-3);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem;
		font-size: 1vw;
		&:hover {
			border: 1px solid var(--blue-1);
		}
		.properties {
			z-index: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding-left: 0.2rem;
		}
		svg {
			width: auto;
			height: 31%;
			fill: #41749280;
			position: absolute;
			z-index: 0;
		}
	}

	.info {
		height: 100%;
		width: 100%;
		padding: 0.5rem;
	}

	.visualInfo div {
		flex: 1;
    display: flex;
		flex-wrap: wrap;
		min-width: 25%;
    height: 30%;
    border: 1px solid var(--blue-3);
		padding: 0.2rem 0 0 0.2rem;
		align-items: center;
    justify-content: center;
    margin: 0.2rem;
		&:hover {
			border: 1px solid var(--blue-1);
		}
	}

	.visualInfo {
		display: flex;
		flex-wrap: wrap;
		min-width: 60%;
		height: 100%;
		.location, .destination {
			min-width: 9rem;
		}

		.location {
			flex: 60%;
		}
		.heading {
			flex: 35%;
		}
		.course {
			flex: 45%;
		}
		.avgSpeed {
			flex: 45%;
		}
		.destination {
			flex: 100%;
		}
		
		.icon {
			height: 60%;
			width: auto;
			margin-right: 0.3rem;
			path {
				fill: var(--blue-1);
			}
		}

		.text {
			border: none;
			&:hover{
				border:none;
			}
		}

		.legend{
			font-size: 0.8vw;
    	border: none;
    	color: var(--blue-2);
    	min-width: 100%;
    	margin: 0;
    	padding: 0;
			&:hover{
				border: none;
				color: var(--blue-1);
				
			}
		}
	}
}