.heading {
	width: 100%;
	height: 10%;
	display: flex;
	background-color: var(--blue-4);
	padding: 0 0.5rem;
	justify-content: space-between;
	align-items: center;
	text-align: center;

	.ids {
		font-size: .9vw;
		display: flex;
		flex-direction: column;
		color: orangered;
		font-style: italic;
	}

	.imgContainer{
    max-width: 20%;
    height: 100%;
    padding: 0.7vw;
    display: flex;
	}

	.name {
		font-size: var(--font-wide-3);
		color: var(--blue-1);
		display: flex;
		align-items: center;
		margin-left: auto;
	}
}