.container {
	overflow-y: auto;
	width: 100%;
	flex: auto;
	padding: 0.2rem;
	align-items: flex-start;
	flex-direction: column;

	.card {
		max-height: 6rem;
		width: 100%;
		display: flex;
		align-items: center;
		margin: 0.1rem;
		padding: 0.1rem;
		border: 1px solid var(--blue-4);
		cursor: pointer;
		&:hover {
			border: 1px solid var(--blue-1);
		}
		.icon {
			font-size: 1.5rem;
			color: var(--yellow);
			margin-right: 0.5rem;
		}
		.text {
			font-size: 0.8rem;
			color: var(--blue-1);
		}
	}
}