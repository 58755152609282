.cursorLocation{
	display: flex;
	align-items: center;
	color: var(--chile-brown);
	font-style: italic;
	margin: 0 0.5rem;
	background: var(--blue-4);
	padding: 0.2vw 0.5vw;
	border-radius: 0.5vw;
	font-size: 0.8vw;
	&:hover {
		background: var(--blue-3);
		border: var(--blue-1);
		color: white;
	}
}