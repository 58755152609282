* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
  --blue-1: #40a7dc;
	--blue-2: #3286bb;
	--blue-3: #136f9f;
	--blue-4: #144e84;
	--blue-5: #001c34;
	--yellow: #ebc200;
	--gray: #e3e4e6;
	--chile-blue: rgb(0, 54, 167);
	--chile-red: rgb(216, 39, 25);
	--chile-white: rgb(255, 255, 255);
	--chile-brown: rgb(207, 150, 98);

	--font-wide-1: 1.4vh;
	--font-wide-2: 2.2vh;
	--font-wide-3: 2.8vh;
	--font-wide-4: 3.8vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar-track {
  /* border: 1px solid white; */
  /* background-color: rgb(126, 136, 182); */
}

::-webkit-scrollbar {
  width: 10px;
  background-color: var(--gray);
}

::-webkit-scrollbar-thumb {
  background-color: var(--blue-4);
  border-radius: 5px;
}