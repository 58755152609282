.SettingsGroup{
	width: 35%;
	margin-right: 1rem;
}

fieldset {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	margin: 1rem;
	width: 100%;
	border: 1px solid var(--blue-4);
	height: 95%;
	overflow-y: auto;
	&:hover{
		border: 1px solid var(--blue-1);
	}
	
	legend {
		background-color: var(--blue-4);
		color: var(--gray);
		padding: 0.5rem;
	}

	label {
		color: var(--gray);
		margin-bottom: 0.6rem;
		display: flex;
		&:hover{
			color: var(--blue-1);
		}

		.text {
			margin-right: 1rem;
		}


		.slider_container {
			display: flex;
			justify-content: flex-end;
			margin-left: auto;
		}
	}
}

.BooleanWithInputs{
	color: white;
	width: 100%;
	border: 1px solid var(--blue-4);
	padding: 0.5rem;
	margin: 0.1rem 0;
	cursor: pointer;
	&:hover{
		border: 1px solid var(--blue-1);
	}
	.checkboxText{
		font-style: italic;
		color: var(--chile-brown);
	}

	.options{
		display: flex;
		flex-direction: column;

		
	}
}

.inputSet {
	display: flex;
	flex-direction: column;

	padding: 0.5rem;
	margin: 1rem 0.2rem;
	border: 1px solid var(--blue-3);

	.inputPairLegend{
		margin-left: auto;
		font-size: 0.8rem;
		display: flex;
		p{
			width: 3.5rem;
		}	
	}

	.inputPair{
		margin-left: auto;
		input{
			margin-right: 0.5rem;
		}
	}

	&:hover{
		border: 1px solid var(--blue-1);
	}
	
	legend {
		background-color: var(--blue-3);
		color: var(--gray);
		padding: 0.5rem;
	}

	label {
		color: var(--gray);
		margin-bottom: 0.6rem;
		display: flex;
		cursor: pointer;
		&:hover{
			color: var(--blue-1);
		}

		input {
			width: 3.5rem;
			height: 1.5rem;
			margin-left: auto;
			background-color: var(--yellow);
			outline: none;
			border: none;
			padding: 0.5rem;

			&:focus {
				border-bottom: 3px solid var(--yellow);
				background-color: var(--blue-5);
				color: var(--gray);
			}
		}
	}
}