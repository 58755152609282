.markersPanel {
	display: flex;
	background-color: var(--blue-5);
	flex-direction: column;
	padding: 1rem;
	overflow-y: auto;
	width: 21rem;
	align-items: flex-start;

	fieldset {
		display: flex;
		max-height: 10rem;
    min-height: 10rem;
		align-items: center;
		margin: 0.5rem 0rem;
		padding-bottom: 0.5rem;
		border: 1px solid var(--blue-3);
		
		legend {
			font-size: 0.8rem;
			font-style: italic;
			color: var(--blue-1);
		}
		label {
			width: 90%;

			justify-content: space-around;
			display: flex;
			flex-direction: column;
			span {
				font-size: 0.8rem;
				color: gray;
			}
			input {
				max-width: 8.5rem;
			}
		}
	}
}
