.footbar {
  max-height: 7vh;
  min-height: 7vh;
  width: 100%;
  background-color: var(--blue-4);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
	border-top: 1px solid var(--blue-2);
	font-size: 2.2vh;


	a {
		transition: all 1s;
		color: var(--blue-1);
		text-decoration: none;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	
	svg {
		transition: all 1s;
		font-size: 5vh;
		vertical-align: middle;
		path {
			transition: all 1s;
			fill: var(--blue-2);
			stroke: var(--blue-5);
		}
	}

	.activeNavLink {
		letter-spacing: 3px;
		font-weight: bold;
		color: var(--chile-brown);
		text-decoration: underline;
		svg {
			box-shadow: 0 0 0.1rem 0.1rem var(--chile-brown);
			border-radius: 6px;
			path {
				fill: var(--chile-brown);
			}
		}
	}
}