.toolbarButton{
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 0.04vw 0.2vw;
	min-width: 5.5vw;
	border-radius: 0.4vw;
	font-size: 0.8vw;
	margin: 0.1vw;
	
	.menuIcon {
		height: 1.5vw;
		width: 1.5vw;
		margin-right: 0.2vw;

		svg{
			path {
				stroke: var(--blue-2);
			}
			&:hover {
				path {stroke: var(--chile-brown);}
			}
		}
	}
}

.blue{
	border: 1px solid var(--blue-1);
	background: var(--blue-5);
	color: var(--blue-1);
	path {stroke: var(--blue-1);}
	&.true, &:hover {
		border: 1px solid var(--blue-5);
		background: var(--blue-1);
		color: var(--blue-5);
		path {stroke: var(--blue-5);}
	}
	&.true{
		color: white;
	}
}

.yellow{
	border: 1px solid var(--chile-brown);
	background: var(--blue-5);
	color: var(--chile-brown);
	path {stroke: var(--chile-brown);}
	&.true, &:hover {
		border: 1px solid var(--blue-5);
		background: var(--chile-brown);
		color: var(--blue-5);
		path {stroke: var(--blue-5);}
	}
	&.true{
		color: white;
	}
}