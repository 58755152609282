.infoBar {
	display: flex;
	background-color: var(--blue-5);
	color: var(--yellow);
	padding: 0.5rem;
	max-height: 7vh;
	min-height: 7vh;
	align-items: center;

	.satteliteButton{
		font-size: 1.5rem;
		border: 1px solid yellow;
		width: 2rem;
		height: 2rem;
		cursor: pointer;
		border-radius: 2px;
		&:hover{
			border: 1px solid var(--chile-red);
			color: var(--chile-red);
		}
	}

	.logo {
		max-width: 5rem;
		margin-right: auto;
	}
	.info_panel {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		font-size: var(--font-wide-2);
		border: 1px solid var(--blue-4);
		margin: 1rem;

		.textAndValue{
			display:flex;
		}
	
		.text{
			margin-right: 0.5vw;
		}

		.menuIcon {
			height: var(--font-wide-4);
			width: var(--font-wide-4);
			cursor: pointer;
			margin-right: 1vh;

			path {
				stroke: var(--blue-2);
			}
			&:hover {
				path {stroke: var(--chile-brown);}
			}
		}

		.value {
			color: var(--chile-red);
		}
		&:hover {
			border: 1px solid var(--blue-1);
		}
	}
}