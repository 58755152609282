.contextButton {
	height: var(--font-wide-4);
	width: var(--font-wide-4);
	cursor: pointer;
	margin-right: 1vh;
	vertical-align: middle;

	path {
		stroke: var(--blue-2);
		fill: var(--chile-blue);
	}

	&:hover {
		path {stroke: var(--chile-brown);}
	}
}