.settingsScreen {
	display: flex;
	height: 100%;
	padding: 1rem;
	overflow-y: scroll;
	flex-direction: column;

	.options{
		height: 90%;
		display: flex;
	}
	.toolbar{
		display: flex;
		height: 10%;
		justify-content: flex-end;
	}
}