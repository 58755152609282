.vertical{
	height: 100%;
	min-width: 13%;
  max-width: 13%;
	background-color: var(--blue-5);
	display: flex;
	flex-direction: column;
	align-items: center;

	.alertsFilter {
		font-size: var(--font-wide-2);
    margin: 0 0.3rem 0.5rem;
    background: var(--blue-4);
    color: white;
    padding: 0.2rem;
    outline: none;
    border: 1px solid var(--blue-5);
		&:hover, &:focus {
			border: 1px solid var(--blue-1);
		}

		option {
			font-size: small;
		}
	}
}