.ReportsScreen {
	display: flex;
	width: 100%;
	height: 100%;

	.SideBar{
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		min-width: 20%;	
		color: white;
		
		fieldset{
			max-width: 85%;
			min-height: 6.5rem;
		}

		input[type=checkbox] {
			vertical-align: middle;
			min-width: 1.2rem;
			min-height: 1.2rem;
			max-width: 1.2rem;
			max-height: 1.2rem;
		}

		.DateTime{
			min-height: 10rem;
		}
		
		.Position, .Size{
			min-height: 10rem;
			display: flex;
			flex-direction: row;
			
			.MinMax{
				display: flex;
				flex-direction: column;
				width: 50%;
				input{
					width: 5rem;
				}
			}
		}
	}

	.ResultsContainer{
		display: flex;
		background: var(--blue-4);
		color: white;
		padding: 2rem;
		font-size: 0.7rem;
		overflow: auto;

		.loadingCell{
			font-size: 2rem;
      text-align: center;
		}

		thead{
			font-weight: bold;
			font-size: 1rem;
			background: var(--blue-3);
		}
		td{
			padding: 0.5rem;
			border: 1px solid black;
		}
		tr{
			&:hover{
				background: var(--blue-5);
			}
		}

	}
}